import Tracker from '@xFrame4/common/Tracker';

export class WebflowTracker extends Tracker
{
    protected static createInstance(): WebflowTracker
    {
        return new WebflowTracker();
    }

    async init()
    {
        this.handleScriptLoading = false;
        this.trackWithGoogle = true;
        this.trackWithFacebook = true;
        this.trackWithFacebookConversionsApi = true;
        this.trackWithGoogleMeasurementProtocol = true;
        this.trackWithReddit = true;
        this.trackWithRedditConversionsApi = true;

        this.on('loaded', () => {
            /** Attach tracking event listeners to the window and to the HTML elements. */
            this.logThankYouPage();
            this.logPurchaseThankYouPage();
            this.logDynamicClicks();
            this.logFixedClicks();
        });

        await super.init();
    }
    
    /** 
     * Track a thank you page event
     */
    logThankYouPage()
    {
        console.log('logThankYouPage - init');

        if ((location.pathname as string).indexOf('thank-you') != -1)
        {
            // Define thank you pages
            let thankYouPages = [
                {
                    name: 'lead_pickleball',
                    url: 'thank-you-pickleball'
                },
                {
                    name: 'subscription_thank_you',
                    url: 'thank-you-subscription'
                }
            ];
            
            // Log event
            for (let thankYouPage of thankYouPages)
            {
                if ((location.pathname as string).indexOf(thankYouPage.url) != -1)
                {
                    this.logEvent('lead_' + thankYouPage.name, {
                        category: 'lead', 
                        label: thankYouPage.name
                    });  
                    break;
                }
            }

            // Redirect
            console.log('logThankYouPage - redirect to Home');
            setTimeout(() => {
                location.replace(location.origin); // https://stackoverflow.com/questions/1865837/whats-the-difference-between-window-location-and-window-location-replace
            }, 15 * 60 * 1000); // redirect to home X seconds after the page load
        }
    }

    /**
     * Track a purchase thank you page event
     */
    logPurchaseThankYouPage()
    {
        let purchaseThankYouPages = [
            {
                url: 'general-booking',
                eventName: 'booked_general',
                value: 1000,
                currency: 'USD'
            },
            {
                url: 'pickleball-booking',
                eventName: 'booked_pickleball',
                value: 1000,
                currency: 'USD'
            },
            {
                url: 'booked-dw-event',
                eventName: 'booked_dw',
                value: 1000,
                currency: 'USD'
            }
        ];

        for (let purchaseThankYouPage of purchaseThankYouPages)
        {
            if ((location.pathname as string).indexOf(purchaseThankYouPage.url) != -1)
            {
                this.logEvent(purchaseThankYouPage.eventName, {
                    value: purchaseThankYouPage.value,
                    currency: purchaseThankYouPage.currency
                });
                break;
            }
        }
    }

    /**
     * Log click events on element dynamically.
     * Tracked elements have the attribute [data-track-click="true"]
     * The event name is marked with the attribute [data-track-click-event="EVENT_NAME"]
     * Additional parameters are marked with attributes in the following format: [data-track-click-<PARAMETER_NAME>="PARAMETER_VALUE"]
     */
    logDynamicClicks()
    {
        console.log('logDynamicClicks - init');
        
        let trackedElements = document.body.querySelectorAll('[data-track-click="true"]');
        trackedElements.forEach(element => {
            // get the event name
            let eventName = element.getAttribute('data-track-click-event');
            if (eventName != null)
            {
                element.addEventListener('click', () => {
                    // build the parameters
                    let parameters: any = {};
                    let parameterAttributes = element.attributes;
                    for (let i = 0; i < parameterAttributes.length; i++)
                    {
                        let attribute = parameterAttributes[i];
                        if (attribute.name.indexOf('data-track-click-') == 0)
                        {
                            let parameterName = attribute.name.replace('data-track-click-', '');
                            if (parameterName == 'event') continue; // skip the event name
                            let parameterValue: any = attribute.value;
                            if (!isNaN(parameterValue as any)) parameterValue = Number(parameterValue); // convert to number if possible
                            parameters[parameterName] = parameterValue;
                        }
                    }
                    
                    // log the event
                    this.logEvent(eventName!, parameters);
                });
            }
        });  
    }

    /**
     * Log clicks on the page.
     */ 
    logFixedClicks()
    {
        // log clicking on mailto links
        let mailtoLinks = document.body.querySelectorAll('a[href^="mailto:"]');
        mailtoLinks.forEach(link => {
            link.addEventListener('click', () => {
                this.logEvent('click_mailto');
            });
        });

        // log clicking on phone links
        let phoneLinks = document.body.querySelectorAll('a[href^="tel:"]');
        phoneLinks.forEach(link => {
            link.addEventListener('click', () => {
                this.logEvent('click_phone');
            });
        });

        // log clicking on address (links that contain 'https://goo.gl/maps')
        let addressLinks = document.body.querySelectorAll('a[href*="https://goo.gl/maps"]');
        addressLinks.forEach(link => {
            link.addEventListener('click', () => {
                this.logEvent('click_address');
            });
        })

        // log clicking on links that begin with 'https://www.virginvoyages.com/book/'
        let virginVoyagesLinks = document.body.querySelectorAll('a[href^="https://www.virginvoyages.com/book/"]');
        virginVoyagesLinks.forEach(link => {
            link.addEventListener('click', () => {
                this.logEvent('begin_vv_booking');
            });
        });

        // log clicking on Stripe payment links (link begins with 'https://buy.stripe.com' and does not have the [data-track-click="true"] attribute)
        let stripeLinks = document.body.querySelectorAll('a[href^="https://buy.stripe.com"]:not([data-track-click="true"])');
        stripeLinks.forEach(link => {
            link.addEventListener('click', () => {
                this.logEvent('begin_checkout');
            });
        });    
    }
	
}

export default WebflowTracker;