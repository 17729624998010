import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import InHouseStatus from '@business/forms/InHouseStatus';
import TravelAgent from '@business/agency/TravelAgent';
import LeadSource from '@business/forms/LeadSource';
import LeadContactType from '@business/forms/LeadContactType';

export enum WebflowLeadStatus
{
    NEW = 'NEW',
    APPROVED_FOR_TRACKING = 'APPROVED_FOR_TRACKING',
    NOT_APPROVED_FOR_TRACKING = 'NOT_APPROVED_FOR_TRACKING',

}
export enum WebflowLeadEvyssaStatus
{
    BOOKED = 'BOOKED',
    LOST = 'LOST',
    PENDING = 'PENDING',
    NO_CONTACT = 'NO_CONTACT',

}
export enum WebflowLeadSourceType
{
    WEBFLOW = 'WEBFLOW',
    FB_LEAD_AD = 'FB_LEAD_AD',
    ZOHO_SALES_IQ = 'ZOHO_SALES_IQ',
    MANUAL = 'MANUAL',

}

export class WebflowLeadGeneratedEntityManager<B extends WebflowLeadGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'WebflowLead',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'dateAdded', type: BusinessEntityFieldType.DateTime, isInput: false },
				{ name: 'email', type: BusinessEntityFieldType.VarChar },
				{ name: 'status', type: BusinessEntityFieldType.VarChar },
				{ name: 'evyssaStatus', type: BusinessEntityFieldType.VarChar },
				{ name: 'inHouseStatus', type: BusinessEntityFieldType.ForeignKey, inputName: 'inHouseStatusId', inputProperty: 'inHouseStatus.id', relatedManager: InHouseStatus.manager },
				{ name: 'inHouseStatusChangedDate', type: BusinessEntityFieldType.DateTime, isInput: false },
				{ name: 'sourceType', type: BusinessEntityFieldType.VarChar },
				{ name: 'name', type: BusinessEntityFieldType.VarChar },
				{ name: 'phone', type: BusinessEntityFieldType.VarChar },
				{ name: 'fieldsAndValues', type: BusinessEntityFieldType.Json },
				{ name: 'sourceUrl', type: BusinessEntityFieldType.VarChar },
				{ name: 'sourceName', type: BusinessEntityFieldType.VarChar },
				{ name: 'formId', type: BusinessEntityFieldType.VarChar },
				{ name: 'submitEventName', type: BusinessEntityFieldType.VarChar },
				{ name: 'approveEventName', type: BusinessEntityFieldType.VarChar },
				{ name: 'gaClientId', type: BusinessEntityFieldType.VarChar },
				{ name: 'fbp', type: BusinessEntityFieldType.VarChar },
				{ name: 'fbc', type: BusinessEntityFieldType.VarChar },
				{ name: 'clientBudget', type: BusinessEntityFieldType.Decimal },
				{ name: 'clientBudgetCurrency', type: BusinessEntityFieldType.VarChar },
				{ name: 'revenue', type: BusinessEntityFieldType.Decimal },
				{ name: 'revenueCurrency', type: BusinessEntityFieldType.VarChar },
				{ name: 'sendCustomEmails', type: BusinessEntityFieldType.Boolean },
				{ name: 'travelDestination', type: BusinessEntityFieldType.VarChar },
				{ name: 'comments', type: BusinessEntityFieldType.Text },
				{ name: 'lostBecasuse', type: BusinessEntityFieldType.VarChar },
				{ name: 'createdByTravelAgent', type: BusinessEntityFieldType.ForeignKey, inputName: 'createdByTravelAgentId', inputProperty: 'createdByTravelAgent.id', relatedManager: TravelAgent.manager },
				{ name: 'assignedToTravelAgent', type: BusinessEntityFieldType.ForeignKey, inputName: 'assignedToTravelAgentId', inputProperty: 'assignedToTravelAgent.id', relatedManager: TravelAgent.manager },
				{ name: 'leadSource', type: BusinessEntityFieldType.ForeignKey, inputName: 'leadSourceId', inputProperty: 'leadSource.id', relatedManager: LeadSource.manager },
				{ name: 'leadContactType', type: BusinessEntityFieldType.ForeignKey, inputName: 'leadContactTypeId', inputProperty: 'leadContactType.id', relatedManager: LeadContactType.manager },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'webflowLeads',
        });
    }

}
/**
 * A lead from a Webflow contact form. It's called WebflowLead for because originally it was created to store leads from Webflow forms, but it can be used for any other type lead source.
 */
export class WebflowLeadGenerated extends BusinessEntity
{
	dateAdded!: string;
	email: string | null = null;
	status: WebflowLeadStatus = WebflowLeadStatus.NEW;
	/** The status of the lead in Evyssa. What happened with the qualified lead? */
	evyssaStatus: WebflowLeadEvyssaStatus | null = null;
	/** The status of the lead at the travel agency. */
	inHouseStatus: InHouseStatus | null = null;
	/** The date when the in-house status last changed. */
	inHouseStatusChangedDate: string | null = null;
	/** The type of form where the lead was submitted. MANUAL means the lead was created manually within the app. */
	sourceType: WebflowLeadSourceType = WebflowLeadSourceType.MANUAL;
	name: string | null = null;
	phone: string | null = null;
	/** The fields and values of the Webflow form. */
	fieldsAndValues: any | null = null;
	/** The URL of the page where the form was submitted. */
	sourceUrl: string | null = null;
	/** The name of the form or the page where the form was submitted. */
	sourceName: string | null = null;
	formId: string | null = null;
	/** The name of the event to send when a contact form is submitted. */
	submitEventName: string = 'thank_you_lead';
	/** The name of the event to send when a lead is approved for tracking. */
	approveEventName: string = 'approved_lead';
	gaClientId: string | null = null;
	fbp: string | null = null;
	fbc: string | null = null;
	clientBudget: number | null = null;
	clientBudgetCurrency: string = 'USD';
	revenue: number | null = null;
	revenueCurrency: string = 'USD';
	sendCustomEmails: boolean = true;
	travelDestination: string | null = null;
	/** Comments about the lead. */
	comments: string | null = null;
	/** The reason why the lead was lost. */
	lostBecasuse: string | null = null;
	/** The travel agent who created the lead. */
	createdByTravelAgent: TravelAgent | null = null;
	/** The travel agent assigned to the lead. */
	assignedToTravelAgent: TravelAgent | null = null;
	/** The source of the lead. */
	leadSource: LeadSource | null = null;
	/** The type of lead contact. */
	leadContactType: LeadContactType | null = null;

}

export default WebflowLeadGenerated;