import client from '@xFrame4/business/GraphQlClient';
import { replaceAll } from '@xFrame4/common/Functions';
import BusinessEntity, { BusinessEntityFilter, BusinessEntityPaging, EntityManager } from './BusinessEntity';

export enum ManyToManyCollectionAction
{
    Add = 'add',
    Remove = 'remove',
    Set = 'set',
    Clear = 'clear',
}

export class ManyToManyCollection<C extends BusinessEntity>
{
    /** The entity that owns the collection. Eg: author. */
    entity: BusinessEntity;
    /** The full class name of the entity, including namespace. Eg: business\blog\Author. */
    entityFullClassName: string;
    /** The class of the entity. Eg: Author. */
    entityClassName: string;
    /** The name of the collection. Eg: posts */
    collectionName: string;
    /** The full class name of the collection class, including namespace. Eg: business\blog\Post. */
    collectionFullClassName: string;
    /** The class of the collection. Eg: Post. */
    collectionClassName: string;
    /** The entity manager for the collection class. */
    collectionClassEntityManager: EntityManager<C>;
    /** The related query name for filtering and loading the collection. */
    relatedQueryName?: string;

    /**
     * Constructor.
     * 
     * @param entity The entity that owns the collection.
     * @param entityClassFullName The full class name of the entity, including namespace. Eg: business/blog/Author.
     * @param collectionName The name of the collection. Eg: posts.
     * @param collectionClassFullName The full class name of the collection class, including namespace. Eg: business/blog/Post.
     * @param collectionClassEntityManager The entity manager for the collection class.
     * @param relatedQueryName The related query name for filtering and loading the collection. Eg: author. If not specified, the related query name will be the camel case of the entity class name. Eg: author.
     */
    constructor(entity: BusinessEntity, entityClassFullName: string, collectionName: string, collectionClassFullName: string, collectionClassEntityManager: EntityManager<C>, relatedQueryName?: string)
    {
        this.entity = entity;
        this.entityFullClassName = entityClassFullName;
        this.entityClassName = entityClassFullName.split('/').pop() as string;
        this.collectionName = collectionName;
        this.collectionFullClassName = collectionClassFullName;
        this.collectionClassName = collectionClassFullName.split('/').pop() as string;
        this.collectionClassEntityManager = collectionClassEntityManager;
        this.relatedQueryName = relatedQueryName;
    }

    /**
     * Load the collection.
     * 
     * @param filter The filters as specified in the GraphQL schema.
     * @param paging The paging data for the results.
     * @returns A paginated collection of entities.
     */
    async load(filter: BusinessEntityFilter = {}, paging?: BusinessEntityPaging)
    {
        let entityClassNameCamelCase = this.entityClassName.charAt(0).toLowerCase() + this.entityClassName.slice(1);
        let relatedQueryName = this.relatedQueryName ?? entityClassNameCamelCase;

        if (this.entity.id != null)
        {
            let baseFilter: BusinessEntityFilter = { };
            baseFilter[relatedQueryName + '_Id'] = this.entity.id;
            
            return this.collectionClassEntityManager.load({ ...baseFilter, ...filter }, paging);
        }
        else
        {
            return null;
        }
    }

    /**
     * Get an entity from the collection.
     * 
     * @param filter The filter as specified in the GraphQL schema.
     * @returns The entity or null if nothing or more than one entities are found.
     */
    async get(filter: BusinessEntityFilter)
    {
        return this.collectionClassEntityManager.get({ id: this.entity.id, ...filter });
    }
    
    /**
     * Add entities to the collection.
     * 
     * @param entities The entities to add.
     * @returns True if the mutation was successful.
     */
    async add($entities: C[])
    {
        return this.mutate(ManyToManyCollectionAction.Add, $entities);
    }

    /**
     * Remove entities from the collection.
     * 
     * @param entities The entities to remove.
     * @returns True if the mutation was successful.
     */
    async remove(entities: C[])
    {
        return this.mutate(ManyToManyCollectionAction.Remove, entities);
    }
    
    /**
     * Set the entities in the collection.
     * 
     * @param entities The entities to set.
     * @returns True if the mutation was successful.
     */
    async set(entities: C[])
    {
        return this.mutate(ManyToManyCollectionAction.Set, entities);
    }   
    
    /**
     * Clear the collection.
     * 
     * @returns True if the mutation was successful.
     */
    async clear()
    {
        return this.mutate(ManyToManyCollectionAction.Clear, []);
    }

    /**
     * Execute the ManyToMany manage mutation.
     * 
     * @param action The action to execute.
     * @param entities The entities to add/remove/set.
     * @returns True if the mutation was successful.
     */
    protected async mutate(action: ManyToManyCollectionAction, entities: C[])
    {       
        const { gql } = await import('@apollo/client');
        
        let mutation = `
        mutation ManageManyToManyCollection ($action: String!, $entityId: Int!, $collectionEntityIds: [Int]!, $entityClass: String!, $collectionClass: String!, $collectionName: String!) {
            manageManyToManyCollection${this.entityClassName} (action: $action, entityId: $entityId, collectionEntityIds: $collectionEntityIds, entityClass: $entityClass, collectionClass: $collectionClass, collectionName: $collectionName) {
                success
            }
        }
        `;

        let variables = {
            action: action,
            entityId: this.entity.id,
            collectionEntityIds: entities.map(entity => entity.id),
            entityClass: replaceAll( '/', '\\', this.entityFullClassName), // 'business\blog\Author
            collectionClass: replaceAll('/', '\\', this.collectionFullClassName), // 'business\blog\Post
            collectionName: this.collectionName,
        };

        try
        {
            let result = await client.mutate({
                mutation: gql(mutation),
                variables: variables
            });

            let success = result.data[`manageManyToManyCollection${this.entityClassName}`].success;
            if (!success)
            {
                console.log('M2M Mutation failed: ' + mutation);
                console.log('M2M Variables: ', JSON.stringify(variables));
                console.log('M2M error: ', result.data[`manageManyToManyCollection${this.entityClassName}`].message);
            }

            return success;
        }
        catch (error)
        {
            console.log('M2M Mutation failed: ' + mutation);
            console.log('M2M Variables: ', JSON.stringify(variables));
            console.log('M2M error: ', (error as Error).message);
            return false;
        }
    }
}

export default ManyToManyCollection;