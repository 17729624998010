import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';



export class LeadContactTypeGeneratedEntityManager<B extends LeadContactTypeGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'LeadContactType',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'name', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'orderNo', type: BusinessEntityFieldType.Integer, isRequiredInput: true },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'leadContactTypes',
        });
    }

}
/**
 * How the lead contacted the travel agency or how the travel agency contacted the lead.
 */
export class LeadContactTypeGenerated extends BusinessEntity
{
	/** The name of the contact type. */
	name!: string;
	orderNo!: number;

}

export default LeadContactTypeGenerated;