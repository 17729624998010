import LeadContactTypeGenerated, { LeadContactTypeGeneratedEntityManager } from './generated/LeadContactType.generated';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';

export class LeadContactTypeEntityManager extends LeadContactTypeGeneratedEntityManager<LeadContactType>
{
    constructor()
    {
        super({
            createEntity: () => new LeadContactType(),
        });
    }
}

export class LeadContactType extends LeadContactTypeGenerated
{
    static _manager: LeadContactTypeEntityManager;
    static get manager(): LeadContactTypeEntityManager
    {
        if (!this._manager) this._manager = new LeadContactTypeEntityManager();
        return this._manager;
    }
}

export default LeadContactType;