import { gql } from '@apollo/client';
import client from '@xFrame4/business/GraphQlClient';
import config from '@config';
import WebflowLeadGenerated, { WebflowLeadGeneratedEntityManager, WebflowLeadStatus } from './generated/WebflowLead.generated';
import AdminTracker from '@business/tracking/AdminTracker';
import { BusinessEntityFilter, EntityManager } from '@xFrame4/business/base/BusinessEntity';

export class WebflowLeadEntityManager extends WebflowLeadGeneratedEntityManager<WebflowLead>
{
    constructor()
    {
        super({
            createEntity: () => new WebflowLead(),
        });
    }
}

export class WebflowLead extends WebflowLeadGenerated
{
    static manager: WebflowLeadEntityManager = new WebflowLeadEntityManager();
    sendCustomEmails: boolean = false;
    static genericSubmitEventName: string = 'thank_you_lead';

    /**
     * Remove the https://www.evyssavacations.com part from the source url
     */
    get sourceUrlStripped(): string
    {
        if (this.sourceUrl == null)
        {
            return '';
        }
        
        return this.sourceUrl.replace('https://www.evyssavacations.com', '');
    }

    /**
     * Approve the lead for tracking
     */
    async approveForTracking()
    {
        if (this.status === WebflowLeadStatus.APPROVED_FOR_TRACKING) return;
        
        // set status to approved
        this.status = WebflowLeadStatus.APPROVED_FOR_TRACKING;
        await this.save();
        
        // create the parameters
        let parameters = {} as any;
        parameters.email = this.email;
        parameters.value = this.clientBudget ?? 0;
        parameters.revenue = this.revenue ?? 0;
        parameters.currency = this.revenueCurrency;
        parameters.client_budget = this.clientBudget ?? 0;

        // log with Google Analytics Measurement Protocol
        AdminTracker.instance.logGoogleMeasurementProtocolEvent(this.approveEventName!, parameters, {
            gaClientId: this.gaClientId ?? undefined,
        });

        // Log with Google Ads
        AdminTracker.instance.logGoogleAdsEvent(config.googleAdsConversionIdApproveLead, parameters, {
            emails: [this.email ?? ''],
            phoneNumbers: [this.phone ?? ''],
        });

        // Log with Facebook Conversions API
        AdminTracker.instance.logFacebookConversionsApiEvent(this.approveEventName!, this.sourceUrl ?? '', parameters, {
            emails: [this.email ?? ''],
            phoneNumbers: [this.phone ?? ''],
            fbp: this.fbp ?? undefined,
            fbc: this.fbc ?? undefined,
        });

        // Log with Reddit Conversions API
        AdminTracker.instance.logRedditConversionsApiEvent(this.approveEventName!, parameters, {
            emails: [this.email ?? ''],
            phoneNumbers: [this.phone ?? ''],
        });
    }
    
    /**
     * Disapprove the lead for tracking
     */
    async disapproveForTracking()
    {
        if (this.status === WebflowLeadStatus.NEW)
        {
            this.status = WebflowLeadStatus.NOT_APPROVED_FOR_TRACKING;
            await this.save();
        }
    }

    /**
     * Get and save the long lived access token for the Evyssa Facebook page.
     * Does not return the token, just saves it to the database.
     */
    static async getLongLivedAccessTokenForEvyssaFacebookPage(shortLivedAccessToken: string)
    {
        let query = `
        mutation GetLongLivedAccessTokenForEvyssaFacebookPage($accessToken: String!) {
            getLongLivedAccessTokenForEvyssaFacebookPage(accessToken: $accessToken) {
                success
            }
        }
        `;

        let variables = {
            accessToken: shortLivedAccessToken,
        };

        let { data } = await client.mutate({
            mutation: gql(query),
            variables,
        });

        return data.getLongLivedAccessTokenForEvyssaFacebookPage.success as boolean
    }

    /**
     * Get the Zoho Sales IQ authorization URL.
     * Use this URL to authorize the Zoho Sales IQ app and save the refresh token.
     */
    static async getZohoSalesIqAuthorizationUrl()
    {
        let query = `
        mutation GetZohoSalesIqAuthorizationUrl {
            getZohoSalesIqAuthorizationUrl
        }
        `;

        let { data } = await client.mutate({
            mutation: gql(query),
        });

        return data.getZohoSalesIqAuthorizationUrl as string;
    }

    /**
     * Export leads from Webflow to an Excel file.
     * The query will return a base64 encoded string of the Excel file.
     */
    static async exportLeads(filter: BusinessEntityFilter)
    {
        let filterString = EntityManager.createQueryFilterString(filter);
        
        let query = `
        query ExportWebflowLeads {
            exportWebflowLeads ${filterString} 
        }
        `;

        let { data } = await client.query({
            query: gql(query),
        });

        return data.exportWebflowLeads as string;
    }
}

export default WebflowLead;