import LeadSourceGenerated, { LeadSourceGeneratedEntityManager } from './generated/LeadSource.generated';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';

export class LeadSourceEntityManager extends LeadSourceGeneratedEntityManager<LeadSource>
{
    constructor()
    {
        super({
            createEntity: () => new LeadSource(),
        });
    }
}

export class LeadSource extends LeadSourceGenerated
{
    static _manager: LeadSourceEntityManager;
    static get manager(): LeadSourceEntityManager
    {
        if (!this._manager) this._manager = new LeadSourceEntityManager();
        return this._manager;
    }
}

export default LeadSource;